import React from 'react';
import line_icon from "../img/line.png";
import "./FloatingIcon.css";

class LineFloatingIcon extends React.Component {
  handleClick = () => {
    window.location.href = 'https://lin.ee/vd4II3i';
  };

  render() {
    return (
      <div className="floating-icon-container" onClick={this.handleClick}>
        <img src={line_icon} alt="Line_friends" />
      </div>
    );
  }
}


export default LineFloatingIcon;
