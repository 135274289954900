import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import Button from 'react-bootstrap/Button';
import "./HomePage.css";
import Footer from "../MyComponent/footer";
import ProgressCircle from '../MyComponent/ProgressCircles';
import "../MyComponent/Banner.css";
import { Modal, Placeholder } from 'react-bootstrap';
import Placeholder1 from '../MyComponent/placeholder1';

import seafood1 from "../img/seafood1.png";
import expert_seafood_icon from "../img/dafu_seafood_icon.png";
import fish_belly1 from "../img/fish_belly/fishbelly1.jpg"
import fish_belly2 from "../img/fish_belly/fishbelly2.jpg"
import fish_belly3 from "../img/fish_belly/fishbelly3.jpg"
import fishhead from "../img/fish_head/fishhead2.jpg"
import fish_skin2 from "../img/fish_skin/fishskin1.JPG";
import fish_stick1 from "../img/fish_stick/fishstick1.jpg";
import fish_stick2 from "../img/fish_stick/fishstick3.jpg";
import tilapia from "../img/tilapia/tilapia.jpg";
import fish_bone2 from "../img/fish_bone/fishbone3.JPG"
import meatballs2 from "../img/meatball/meatballs3.jpg"
import seafood_banner1 from "../img/seafood_banner1.png"

import { Badge } from 'react-bootstrap';

function HomePage(){
    return(
        <div>
          <Description/>
          <Placeholder/>
          <Banner/>
          <ProgressCircle/>
          <Merchandise/>
          <Order/>
          <Placeholder1/>
          <Footer/>
        </div>
    );
}
export default HomePage

function Description(){
    const boldTextStyle = {
        fontWeight: 'bold',
      };
    return(
    <Row className='mx-3 justify-content-evenly gy-3 d-flex align-items-center'>
      <Col md={4} className='des col-lg-3 text-center'>
          <h5 className='m-3' style={boldTextStyle}>『達富水產，新鮮直達』</h5>
          <img src={expert_seafood_icon} alt="商標" className="img-fluid square-logo"/>
          <p className='m-3' style={boldTextStyle}>提供您最新鮮的海產!</p>
          <p className='m-3' style={boldTextStyle}>高雄30年老字號保證!</p>
      </Col>
      <Col xs={12} sm={12} md={8} lg={8}>
          <Display/>
      </Col>
    </Row>
  
    );
}

function Display(){
    return (
        <Carousel className='rounded border border-dark w-100 h-100 shadow-sm'>
          <Carousel.Item>
            <img
              className="d-block w-100 caroimg"
              src={seafood1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 caroimg"
              src={fish_stick1}
              alt="fourth slide"
            />
          </Carousel.Item>
        </Carousel>
      );
}

function Banner() {
  const [showModal, setShowModal] = useState(false);

  const handleModalShow = () => {
    setShowModal(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className="banner-container">
      <img src={seafood_banner1} alt="Banner_img" onClick={handleModalShow} className="banner-image"/>

      <Modal show={showModal} onHide={handleModalClose} centered size="xl">
        <Modal.Body className="custom-modal-body">
          <img src={seafood_banner1} alt="Banner_img" className="modal-image" />
          <button className="close-button" onClick={handleModalClose}>×</button>
        </Modal.Body>
      </Modal>
    </div>
  );
}



const products = [
    {
      id: 1,
      name: '去刺虱目魚肚(大)',
      price: "大：130元/片",
      price1:"(主打商品)",
      image: fish_belly1,
      description: '本商品為全人工處理，還是有低機率會出現魚刺，食用時請小心。我們會盡力將所有魚刺去除，給顧客最安心的食用體驗！',
      isHot: true,  // 熱賣標誌
    },
    {
      id: 2,
      name: '去刺虱目魚肚(中)',
      price: "中：110元/片",
      image: fish_belly2,
      description: '本商品為全人工處理，還是有低機率會出現魚刺，食用時請小心。我們會盡力將所有魚刺去除，給顧客最安心的食用體驗！',
    },
    {
      id: 3,
      name: '去刺虱目魚肚(小)',
      price: "小：80元/片",
      image: fish_belly3,
      description: '本商品為全人工處理，還是有低機率會出現魚刺，食用時請小心。我們會盡力將所有魚刺去除，給顧客最安心的食用體驗！',
      isOnSale: true,
    },
    {
      id: 4,
      name: '魚頭',
      price: "中：40元/7顆",
      brief: "（微帶肉）",
      image: fishhead,
      description: '',
      isHot: false,
    },
    {
      id: 5,
      name: '魚皮',
      price: "70元/半斤 140元/斤",
      brief: "（微帶肉）",
      image: fish_skin2,
      description: '',
      isHot: true,
    },
    {
      id: 6,
      name: '魚柳',
      price: "70元/半斤 140元/斤",
      image: fish_stick2,
      description: '',
    },
    {
      id: 7,
      name: '魚丸',
      price: "80元/斤",
      image: meatballs2,
      description: '',
    },
    {
      id: 8,
      name: '魚骨',
      price: "30元/斤",
      price1: "（適合熬湯頭）",
      image: fish_bone2,
      description: '',
    },
    {
      id: 9,
      name: '鹹水吳郭魚',
      price: "90元/尾",
      brief: "（一尾12-14兩）",
      image: tilapia,
      description: '',
    },
  ];
  const boldTextStyle = {
    fontWeight: 'bold',
  };

  const ProductCard = ({ product, onItemClick }) => (
    <div className="product-card" onClick={() => onItemClick(product)}>
      {product.isHot && <Badge bg="danger" className="hot-badge">熱賣</Badge>}
      <img src={product.image} alt={product.name} />
      <h4 style={boldTextStyle}>{product.name}</h4>
      <h6>{product.price}{product.isOnSale && <Badge bg="warning" className="sale-badge">特價</Badge>}</h6>
      <h6>{product.price1}</h6>
      <h6>{product.brief}</h6>
    </div>
  );
  
  const ProductDetail = ({ product }) => (
    <div className="product-detail">
      <h2 style={boldTextStyle}>{product.name}</h2>
      <p>{product.price}</p>
      <p>{product.price1}</p>
      <p>{product.description}</p>
    </div>
  );
  
function Merchandise(){
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleProductClick = (product) => {
      setSelectedProduct(product);
      setShowModal(true);  // 打開模態框
  };

  const handleCloseModal = () => {
      setShowModal(false); // 關閉模態框
  };
  
    return (
    <Container className='my-5'>
      <div className="merchandise">
        <h3 style={boldTextStyle}>海產系列-魚</h3>
        <p style={boldTextStyle}>(點擊商品可了解詳細資訊)</p>
        <p style={boldTextStyle}>真空包裝商品可冷凍保存6個月，每份需加價10元。</p>
        <p style={boldTextStyle}>普通包裝商品可冷凍保存1週，請儘速食用完畢。</p>
        {/* 這裡是模態框部分 */}
        <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
          <Modal.Header closeButton style={{ backgroundColor: '#f8f8f8' }}>
            <Modal.Title>商品詳細資訊</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: '30px' }}>
            {selectedProduct && (
                <ProductDetail product={selectedProduct} />)}
          </Modal.Body>
          <Modal.Body style={{ padding: '30px', display: 'flex', justifyContent: 'center' }}>
          {selectedProduct && (
            <img src={selectedProduct.image} alt={selectedProduct.name} style={{ width: '80%', maxHeight: '70%' }} />)}
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: '#f8f8f8' , display: 'flex', justifyContent: 'flex-start' }}>
            <Button variant="secondary" onClick={handleCloseModal}>
              關閉
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="product-list">
          {products.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              onItemClick={handleProductClick}
            />
          ))}
        </div>
      </div>
    </Container>
    );
}

function Order(){
  return(
    <Container className='my-3 '>
    <div className="d-flex justify-content-center">
    <Button variant="primary" size="lg" as={Link} to="/order_pg">
      訂購連結<i className="fas fa-hand-point-up"></i>
    </Button>
    </div>
    </Container>
  );
}