import React, { useState, useEffect } from "react";
import './Announcebar.css';

export default function Announcebar() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const announcements = [
        "🎉滿 $5000元 享免運費優惠!",
        "🚚全台皆可配送到家!（離島另計）",
        "🗣️客服請洽網頁下方電話，或按line圖示！",
        "ㆍ未達免運金額，酌收 $225元物流運費"
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % announcements.length);
        }, 5000); // 每5秒換一次廣告
    
        return () => {
            clearInterval(interval);
        };
    }, [announcements.length]);
    

    return (
        <div className="announce-bar">
            <button type="button">
                <span key={currentIndex} className="announce-text">{announcements[currentIndex]}</span>
            </button>
        </div>
    )
}
