import React, { useEffect} from "react";
import { useState } from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {TransitionGroup,  CSSTransition } from 'react-transition-group';
import LineFloatingIcon from './MyComponent/FloatingIcon';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {LinkContainer} from 'react-router-bootstrap';
import Announcebar from "./MyComponent/Announcebar"
import ScrollToTop from './MyComponent/ScrollToTop';
import "./Layout.css"

function Layout() {
    const location = useLocation();
    
    return(
        <div>
            <ScrollToTop/>
            <Announcebar/>
            <TopNav/>
                <TransitionGroup component={null}>
                    <CSSTransition
                        timeout={600}
                        classNames='fade'
                        key={location.key}
                        unmountOnExit>
                        <Outlet/>
                    </CSSTransition>  
                </TransitionGroup>
            <LineFloatingIcon/>
        </div>
    );
}
export default Layout;


function TopNav() {
    const navbarStyle = {
        margin: '2px',
        borderRadius: '5px',
        position: 'sticky',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
        border: '1.2px solid #4285c7',
    };
    const [expanded, setExpanded] = useState(false);
    
    useEffect(() => {
        const indicator = document.querySelector(".nav-indicator");
        const items = document.querySelectorAll(".nav-item");

        function handleIndicator(el) {
            items.forEach((item) => {
                item.classList.remove("is-active");
                item.removeAttribute("style");
            });

            indicator.style.width = `${el.offsetWidth}px`;
            indicator.style.left = `${el.offsetLeft}px`;
            indicator.style.backgroundColor = el.getAttribute("active-color");

            el.classList.add("is-active");
            el.style.color = el.getAttribute("active-color");
        }

        items.forEach((item) => {
            item.addEventListener("click", (e) => {
                handleIndicator(e.target);
            });
            item.classList.contains("is-active") && handleIndicator(item);
        });
    }, []);

    return (
        <Navbar expand="md" expanded={expanded} className="m-3 rounded sticky" style={navbarStyle}>
            <Container className='p-0 mx-5'>
                <LinkContainer to="homepage" className=' fs-3 calligraphy-font brand-with-logo'>
                    <Navbar.Brand>達富水產🐟</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(prev => !prev)} />
                <Navbar.Collapse id="basic-navbar-nav" className='justify-content-between'>
                <Nav className="w-50 fs-5">
                    <LinkContainer to="" onClick={() => setExpanded(false)}>
                        <Nav.Link className='px-4 border-start border-end border-secondary nav-item is-active' active-color="orange">首頁</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="brandstory_pg" onClick={() => setExpanded(false)}>
                        <Nav.Link className='px-4 border-start border-end border-secondary nav-item' active-color="green">歷史沿革</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="order_pg" onClick={() => setExpanded(false)}>
                        <Nav.Link className='px-4 border-start border-end border-secondary nav-item' active-color="blue">訂購連結</Nav.Link>
                    </LinkContainer>
                    <span className="nav-indicator" />
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

