import "./footer.css"
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons';

function Footer(){
    return(
        <div className='footer'>
            <p/>
            <div className="footer-text">
        <p>合作請洽ㆍ品牌信箱 | dafuseafood886@gmail.com</p>
        <p>連絡電話ㆍ專人客服 | 0983-420556                  </p>
        <p>線上Line客服 | @317ulzeq (或按右邊line圖示) </p>
        <div className="social-icons">
        <span>粉絲專頁｜</span> 
                <a href="https://www.instagram.com/your_instagram_username/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61550906162881" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebookF} size="2x" />
                </a>
            <span>   </span> 
            </div>
            </div>
                <p className="footer-text-copyright">版權所有 仿冒必究 © 2023達富水產 All Rights Reserved.</p>
            </div>
        );
}
export default Footer