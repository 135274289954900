import {Routes, Route, Navigate} from 'react-router-dom';

import './index.css'
import HomePage from "./Home_pg/HomePage";
import Layout from "./Layout";
import OrderPage from './Order_pg/OrderPage';
import BrandStoryPage from './Brand_story/Brand_story';

function App() {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="homepage1" />} />
        <Route path="homepage" element={<HomePage />} />
        <Route path="brandstory_pg" element={<BrandStoryPage />} />
        <Route path="order_pg" element={<OrderPage />} />
      </Route>
      <Route path="/*" element={<Navigate to="/homepage" />} />
    </Routes>
  );
}

export default App;

