import {React, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import './OrderPage.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';


import '@fontsource/roboto/400.css';
import smilingCat from "../img/smiling_cat2.png"
import ProgressCircle from '../MyComponent/ProgressCircles';
import Placeholder1 from '../MyComponent/placeholder1';
import Footer from '../MyComponent/footer';

import fishbelly from "../img/fish_belly/fishbelly4.jpg"
import fishhead from "../img/fish_head/fishhead2.jpg"
import fishskin from "../img/fish_skin/fishskin1.JPG"
import fishstick from "../img/fish_stick/fishstick2.jpg"
import meatball from "../img/meatball/meatballs3.jpg"
import fishbone from "../img/fish_bone/fishbone3.JPG"
import tilapia from "../img/tilapia/tilapia.jpg"

function OrderPage(){
    return(
        <div>
          <Orderform/>
          <ProgressCircle/>
          <Placeholder1/>
          <Footer/>
        </div>
    );
}
export default OrderPage



function Orderform() {
    // 1. 建立 state
    const [productValues, setProductValues] = useState({
    });
    const productPrices = {
            big_belly: 140, big_belly_normal: 130,
            medium_belly:120, medium_belly_normal:110,
            small_belly: 90, small_belly_normal: 80,
            head: 50, head_normal: 40,
            skin_half: 80, skin_half_normal: 70,
            skin_one: 150, skin_one_normal: 140,
            stick_half: 80, stick_half_normal: 70,
            stick_one: 150, stick_one_normal: 140,
            meat: 90, meat_normal: 80,
            bone: 40, bone_normal: 30,
            fish1: 100, fish1_normal: 90
        };
    
    const calculateTotalPrice = () => {
        let total = 0;
        for (let product in productValues) {
            total += productValues[product] * productPrices[product];
        }
        return total;
    }

    const handleProductChange = (name, value) => {
        setProductValues(prev => ({
            ...prev,
            [name]: parseInt(value, 10) || 0
        }));
    };

    const calculateShippingCost = () => {
        return calculateTotalPrice() >= 3000 ? 0 : 225;
    };

    const calculateAllPrice = ()=>{
        return calculateTotalPrice()+calculateShippingCost()
    }

    const [phone, setPhone] = useState('');
    const handleInputChange = (event) => {
        const inputPhone = event.target.value;
        setPhone(inputPhone);  // 這裡，phone 將會是一個字串
    };
    const [TransferCode, setTransferCode] = useState('');
    const handleInputTransferCode = (event) => {
        const inputTransferCode = event.target.value;
        setTransferCode(inputTransferCode);
    };

    const [isPhoneValid, setPhoneValid] = useState(false);
    const [isTransferCodeValid, setTransferCodeValid] = useState(false);

    function isValidPhone(value) {
        const regex = /^09\d{8}$/;
        return regex.test(value);
    }
    function isValidTransferCode(value) {
        const regex = /^\d{5}$/;
        return regex.test(value);
    }

    function handlePhoneChange(e) {
        const value = e.target.value;
        setPhoneValid(isValidPhone(value));
    }
    function handleTransferCodeChange(e) {
        const value = e.target.value;
        setTransferCodeValid(isValidTransferCode(value));
    }
    const isFormValid = isPhoneValid;

    const [open, setOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        const formEle = document.querySelector("form");
        const formData = new FormData(formEle);
        const jsonObject = {};

        for (const [key, value]  of formData.entries()) {
            if(key==="Phone" || key === "Bank"){
                jsonObject[key] ="_"+value
            }
            else{
                jsonObject[key] = value;
            }
        }
        

        fetch("https://script.google.com/macros/s/AKfycbwmSqahJXCh_z7lH7yv27WjXsx0sVxXGcU5y-8MTkCy3qYpSa51tfukTRLY6MPF0kK1zw/exec", {
            method: "POST",
            body: JSON.stringify(jsonObject),
            headers: {
                "Content-Type": "text/plain; charset=utf-8"
            }
        })
        .then((res) => res.json())
        .then((data) => {
            console.log(data);
            if (data.result === 'success') {
                setDialogMessage(data.message + " 您的訂單編號為："+data.orderNumber);
            } else {
                setDialogMessage("系統錯誤發生: " + data.message+"，請協助聯絡客服人員處理！");
            }
            setOpen(true);
            formEle.reset();
        })
        .catch((error) => {
            console.log(error);
            setDialogMessage(error.toString()+"，請協助聯絡客服人員處理！");
            setOpen(true);
        });
}

    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText("77850504411")
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 1500); // reset after 1.5 seconds
            })
            .catch(err => console.error('Failed to copy text: ', err));
    };

const [imageVisibility, setImageVisibility] = useState({
    belly: false,
    head: false,
    skin: false,
    stick: false,
    meatballs: false,
    bone:false,
    tilapia: false,
    // ... 其他摺疊項目的初始狀態
  });

  function toggleCollapsible(item) {
    setImageVisibility(prevState => ({ ...prevState, [item]: !prevState[item] }));
  }

    return (
        <Container maxWidth="md" className="form-container">
        <Box sx={{ mt: 2, mb: 3 }}>
            <Typography variant="h5" fontWeight="bold" align="center" className="form-title">
            達富水產_下單資訊
            </Typography>
        </Box>

        {/* 給使用者看的說明文字 */}
        <Box sx={{ mt: 2, mb: 2, p: 2, borderRadius: 2, borderColor: 'grey.500', border: 1, textAlign: 'start' }}>
        <Typography variant="body1" className="form-description" 
        style={{
            lineHeight: '1.5', // 調整行間距
            fontSize: '16px', // 設定字體大小
            fontWeight: 'bold' // 加粗
        }}>
        1.請在欲訂購的項目填入訂購數量（填入數字即可）。<br/>
        2.訂單金額滿 5000 元(含)享免運。<br/>
        3.訂單金額未滿 5000 元，需冷凍物流運費 225 元。<br/>
        4.離島運費另計，請洽客服人員。<br/>
        5.點擊 ▼ 可查看商品縮圖，▲ 可隱藏縮圖。<br/>
        </Typography>
        </Box>
        <Box sx={{ mt: 1, mb: 1 }} /> {/* 這是一個間隔，確保上下有適當的空間 */}

        <form className="form" onSubmit={handleSubmit}>
        <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
            <Typography variant="h6" className="form-subtitle" align="center">
                商品選擇
            </Typography>
            <Box sx={{ mt: 2, mb: 2 }} />

            <div className="collapsible-row">
            <Typography variant="h8" className="question-description" display="block">
            魚肚（大）</Typography>
            <br/>
            
            <div className="collapsible-icon"
                onClick={() => toggleCollapsible('belly')}
            >
                {imageVisibility.belly ? '▲' : '▼'}
            </div>
            </div>
            {imageVisibility.belly && (
            <div className="collapsible-img-wrapper">
                <img src={fishbelly} alt="belly＿pic"/>
            </div>
            )}
            <Typography variant="h8" className="question-description" align="left">
            真空包裝：140元/片、普通包裝：130元/片</Typography>

            <TextField
                fullWidth
                label="虱目魚肚(大、真空包裝) ➜請於此填入數量"
                name="big_belly"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.big_belly}
                onChange={e => handleProductChange('big_belly', e.target.value)}
                />
            <TextField 
            fullWidth
            label="虱目魚肚(大、普通包裝) ➜請於此填入數量"
            name="big_belly_normal"
            variant="outlined" 
            margin="normal"
            className="form-text-field"
            value={productValues.big_belly_normal}
            onChange={e => handleProductChange('big_belly_normal', e.target.value)}/>
            <div>
            <br/>
            </div>
            
            <Typography variant="h8" className="question-description" display="block">
            魚肚（中）</Typography>
            <Typography variant="h8" className="question-description" align="left">
            真空包裝：120元/片、普通包裝：110元/片</Typography>
            <TextField 
                fullWidth 
                label="虱目魚肚（中、真空包裝）➜請於此填入數量" 
                name="medium_belly" 
                variant="outlined" 
                margin="normal" 
                className="form-text-field"
                value={productValues.medium_belly}
                onChange={e => handleProductChange('medium_belly', e.target.value)}
                />
            <TextField 
                fullWidth 
                label="虱目魚肚(中、普通包裝）➜請於此填入數量" 
                name="medium_belly_normal" 
                variant="outlined" 
                margin="normal" 
                className="form-text-field"
                value={productValues.medium_belly_normal}
                onChange={e => handleProductChange('medium_belly_normal', e.target.value)}
                />
            <div>
            <br/>
            </div>

            <Typography variant="h8" className="question-description" display="block">
            魚肚（小）</Typography>
            <Typography variant="h8" className="question-description" align="left">
            真空包裝：90元/片、普通包裝：80元/片</Typography>
            <TextField 
                fullWidth 
                label="虱目魚肚(小、真空包裝) ➜請於此填入數量" 
                name="small_belly" 
                variant="outlined" 
                margin="normal" 
                className="form-text-field"
                value={productValues.small_belly}
                onChange={e => handleProductChange('small_belly', e.target.value)}
                />
            <TextField 
                fullWidth 
                label="虱目魚肚(小、普通包裝) ➜請於此填入數量" 
                name="small_belly_normal" 
                variant="outlined" 
                margin="normal" 
                className="form-text-field"
                value={productValues.small_belly_normal}
                onChange={e => handleProductChange('small_belly_normal', e.target.value)}
                />
            <div>
            <br/>
            </div>

            <div className="collapsible-row">
            <Typography variant="h8" className="question-description">
            魚頭</Typography>
            <div className="collapsible-icon"
                onClick={() => toggleCollapsible('head')}
            >
                {imageVisibility.head ? '▲' : '▼'}
            </div>
            </div>
            {imageVisibility.head && (
            <div className="collapsible-img-wrapper">
                <img src={fishhead} alt="head_pic"/>
            </div>
            )}
            <Typography variant="h8" className="question-description" align="left">
            真空包裝：50元/斤、普通包裝：40元/斤</Typography>
            <TextField 
                fullWidth
                label="魚頭（真空包裝） ➜請於此填入數量"
                name="head"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.head}
                onChange={e => handleProductChange('head', e.target.value)}
                />
            <TextField 
                fullWidth
                label="魚頭（普通包裝） ➜請於此填入數量"
                name="head_normal"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.head_normal}
                onChange={e => handleProductChange('head_normal', e.target.value)}
                />
            <div>
                <br/>
            </div>

            <div className="collapsible-row">
            <Typography variant="h8" className="question-description" display="block">
            魚皮（半斤組）</Typography>
            <div className="collapsible-icon"
                onClick={() => toggleCollapsible('skin')}
            >
                {imageVisibility.skin ? '▲' : '▼'}
            </div>
            </div>
            {imageVisibility.skin && (
            <div className="collapsible-img-wrapper">
                <img src={fishskin} alt="skin_pic"/>
            </div>
            )}
            <Typography variant="h8" className="question-description" align="left">
            真空包裝：80元/半斤、普通包裝：70元/半斤</Typography>
            <TextField
                fullWidth
                label="魚皮(半斤、真空包裝) ➜請於此填入數量"
                name="skin_half"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.skin_half}
                onChange={e => handleProductChange('skin_half', e.target.value)}
                />
            <TextField
                fullWidth
                label="魚皮(半斤、普通包裝) ➜請於此填入數量"
                name="skin_half_normal"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.skin_half_normal}
                onChange={e => handleProductChange('skin_half_normal', e.target.value)}
                />
            <div>
                <br/>
            </div>

            <Typography variant="h8" className="question-description" display="block">
            魚皮（整斤組）</Typography>
            <Typography variant="h8" className="question-description">
            真空包裝：150元/斤、普通包裝：140元/斤</Typography>
            <TextField
                fullWidth
                label="魚皮(一斤、真空包裝) ➜請於此填入數量"
                name="skin_one"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.skin_one}
                onChange={e => handleProductChange('skin_one', e.target.value)}
                />
            <TextField
                fullWidth
                label="魚皮(一斤、普通包裝) ➜請於此填入數量"
                name="skin_one_normal"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.skin_one_normal}
                onChange={e => handleProductChange('skin_one_normal', e.target.value)}
                />
            <div>
                <br/>
            </div>

            <div className="collapsible-row">
            <Typography variant="h8" className="question-description">
            魚柳（半斤組）</Typography>
            <div className="collapsible-icon"
                onClick={() => toggleCollapsible('stick')}
            >
                {imageVisibility.stick ? '▲' : '▼'}
            </div>
            </div>
            {imageVisibility.stick && (
            <div className="collapsible-img-wrapper">
                <img src={fishstick} alt="stick_pic"/>
            </div>
            )}
            <Typography variant="h8" className="question-description" align="left">
            真空包裝：80元/半斤、普通包裝：70元/半斤</Typography>
            <TextField
                fullWidth
                label="魚柳(半斤、真空包裝) ➜請於此填入數量"
                name="stick_half"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.stick_half}
                onChange={e => handleProductChange('stick_half', e.target.value)}
                />
            <TextField
                fullWidth
                label="魚柳(半斤、普通包裝) ➜請於此填入數量"
                name="stick_half_normal"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.stick_half_normal}
                onChange={e => handleProductChange('stick_half_normal', e.target.value)}
                />
            <div>
                <br/>
            </div>

            <Typography variant="h8" className="question-description" display="block">
            魚柳（整斤組）</Typography>
            <Typography variant="h8" className="question-description" align="left">
            真空包裝：150元/半斤、普通包裝：140元/半斤</Typography>
            <TextField  
                fullWidth
                label="魚柳(一斤、真空包裝) ➜請於此填入數量"
                name="stick_one"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.stick_one}
                onChange={e => handleProductChange('stick_one', e.target.value)}
                />
            <TextField  
                fullWidth
                label="魚柳(一斤、普通包裝) ➜請於此填入數量"
                name="stick_one_normal"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.stick_one_normal}
                onChange={e => handleProductChange('stick_one_normal', e.target.value)}
                />
            <div>
                <br/>
            </div>

            <div className="collapsible-row">
            <Typography variant="h8" className="question-description">
            魚丸</Typography>
            <div className="collapsible-icon"
                onClick={() => toggleCollapsible('meatballs')}
            >
                {imageVisibility.meatballs ? '▲' : '▼'}
            </div>
            </div>
            {imageVisibility.meatballs && (
            <div className="collapsible-img-wrapper">
                <img src={meatball} alt="meatballs_pic"/>
            </div>
            )}
            <Typography variant="h8" className="question-description" align="left">
            真空包裝：90元/斤、普通包裝：80元/斤</Typography>
            <TextField
                fullWidth
                label="魚丸（真空包裝） ➜請於此填入數量"
                name="meat"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.meat}
                onChange={e => handleProductChange('meat', e.target.value)}
                />
            <TextField
                fullWidth
                label="魚丸（普通包裝） ➜請於此填入數量"
                name="meat_normal"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.meat_normal}
                onChange={e => handleProductChange('meat_normal', e.target.value)}
                />
            <div>
                <br/>
            </div>

            <div className="collapsible-row">
            <Typography variant="h8" className="question-description">
            魚骨</Typography>
            <div className="collapsible-icon"
                onClick={() => toggleCollapsible('bone')}
            >
                {imageVisibility.bone ? '▲' : '▼'}
            </div>
            </div>
            {imageVisibility.bone && (
            <div className="collapsible-img-wrapper">
                <img src={fishbone} alt="bone_pic"/>
            </div>
            )}
            <Typography variant="h8" className="question-description" align="left">
            真空包裝：40元/斤、普通包裝：30元/斤</Typography>
            <TextField
                fullWidth
                label="魚骨（真空包裝） ➜請於此填入數量"
                name="bone"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.bone}
                onChange={e => handleProductChange('bone', e.target.value)}
                />
            <TextField
                fullWidth
                label="魚骨（普通包裝） ➜請於此填入數量"
                name="bone_normal"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.bone_normal}
                onChange={e => handleProductChange('bone_normal', e.target.value)}
                />
            <div>
                <br/>
            </div>

            <div className="collapsible-row">
            <Typography variant="h8" className="question-description">
            鹹水吳郭魚</Typography>
            <div className="collapsible-icon"
                onClick={() => toggleCollapsible('tilapia')}
            >
                {imageVisibility.tilapia ? '▲' : '▼'}
            </div>
            </div>
            {imageVisibility.tilapia && (
            <div className="collapsible-img-wrapper">
                <img src={tilapia} alt="tilapia_pic"/>
            </div>
            )}
            <Typography variant="h8" className="question-description" align="left">
            真空包裝：100元/隻、普通包裝：90元/隻</Typography>
            <TextField
                fullWidth
                label="鹹水吳郭魚（真空包裝） ➜請於此填入數量"
                name="fish1"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.fish1}
                onChange={e => handleProductChange('fish1', e.target.value)}
                />
            <TextField
                fullWidth
                label="鹹水吳郭魚（一般包裝） ➜請於此填入數量"
                name="fish1_normal"
                variant="outlined"
                margin="normal"
                className="form-text-field"
                value={productValues.fish1_normal}
                onChange={e => handleProductChange('fish1_normal', e.target.value)}
                />

        
        {/* 以上左側問題區 */}
        </Grid>
  
      {/* 分隔線 */}
        <Grid item xs={1} md={0.8}>
            <Divider orientation="vertical"  className="form-custom-divider" />
        </Grid>
  
        
      <Grid item xs={12} md={5} container  direction="column">
        <Typography variant="h6" className="form-subtitle" align="center">
            訂單資訊</Typography>
            <Box sx={{ mt: 1, mb: 1 }} />
        <Typography variant="h9" className="question-description"align="center">
            以下訂單金額系統將自動計算!<br/>
            顧客請留意確認金額是否正確!</Typography>
            <Box sx={{ mt: 1, mb: 1 }} />

        <Typography variant="h8" className="question-description">
            訂單小計</Typography>
        <TextField
            fullWidth
            label="訂單小計"
            name="TotalPrice"
            variant="outlined"
            margin="normal"
            className="form-text-field"
            value={calculateTotalPrice()+"元"}
            InputProps={{
            readOnly: true,
        }}/>

        <Typography variant="h8" className="question-description">
            運費
        </Typography>
        <TextField
            fullWidth
            label="冷凍物流運費"
            name="ShippingCost"
            variant="outlined"
            margin="normal"
            className="form-text-field"
            value={calculateShippingCost()+"元"}
            InputProps={{
                readOnly: true,
            }}
        />

        <Typography variant="h8" className="question-description">
            訂單總金額
        </Typography>
        <TextField
            fullWidth
            label="訂單總金額(訂單金額+運費)"
            name="AllPrice"
            variant="outlined"
            margin="normal"
            className="form-text-field"
            value={calculateAllPrice()+"元"}
            InputProps={{
                readOnly: true,
            }}
        />
        <Box sx={{ mt: 2, mb: 3 }} />
        
        
        <Typography variant="h6" className="form-subtitle" align="center">
            請轉帳至此帳號</Typography>
        <div className="bank-info-container">
        <Typography variant="body1" className="account-info" >
            銀行：第一商業銀行(007)<br/>
            轉帳號碼：77850504411<br/>
            <Tooltip>
                <Button 
                startIcon={<FileCopyIcon />} 
                onClick={handleCopyClick}
                variant="outlined"
                size="small" 
                aria-label="copy">
                    {isCopied ? '✔已複製!' : '點我複製『達富水產』轉帳號碼'}
                    </Button>
            </Tooltip>
            
        </Typography>
        </div>
        


        <Box sx={{ mt: 2, mb: 2 }} />
        <Typography variant="h6" className="form-subtitle" align="center">
            聯絡人資訊</Typography>
        <Box sx={{ mt: 1, mb: 1 }} />

        <Typography variant="h8" className="question-description">
            姓名(必填)</Typography>
        <TextField fullWidth type="text" label="請填入姓名" required name="Name" variant="outlined" margin="normal" className="form-text-field" />
        
        <Typography variant="h8" className="question-description">
            手機(必填)</Typography>
        <TextField
            fullWidth
            type="text"
            label="請填入手機(格式:0912123456)"
            required
            name="Phone"
            variant="outlined"
            margin="normal"
            value={phone}
            onChange={(e) => {handleInputChange(e);handlePhoneChange(e);} }
            className="form-text-field"
            />{isPhoneValid ? <span>✔️正確</span> : <span>❗請填答與確認格式</span>}
            <Box sx={{ mb: 1}} />
            
        <Typography variant="h8" className="question-description">
            匯款帳號末5碼</Typography>
        <TextField
            fullWidth
            type="text"
            label="請填入您匯款帳號的末5碼(格式:12345)"
            name="Bank"
            variant="outlined"
            margin="normal"
            className="form-text-field"
            value={TransferCode}
            onChange={(e) => {handleTransferCodeChange(e);handleInputTransferCode(e);}}
            />{isTransferCodeValid ? <span>✔️正確</span> : <span/>}
            <Box sx={{  mb: 1 }} />

        <Typography variant="h8" className="question-description">
            地址(必填)</Typography>
        <TextField
            fullWidth
            type="text"
            label="請填入收件地址"
            required
            name="Address"
            variant="outlined"
            margin="normal"
            multiline
            rows={2}
            className="form-text-field" />
        
        <Typography variant="h8" className="question-description">
            備註</Typography>
        <TextField
            fullWidth
            label="若有特殊需求請標註於此"
            type="text"
            name="Note"
            variant="outlined"
            margin="normal"
            multiline
            rows={5}
            className="form-text-field" />

      </Grid>
        </Grid>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Button
            type="submit"
            className="form-submit-btn"
            disabled={!isFormValid}>
            確定下單
        </Button>
        </Box>
        </form>
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title">
            <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle>
            <DialogContent>
                    <img 
                        src={smilingCat}
                        alt="Thankyou_cat" 
                        style={{ width: '30%', height: '30%' ,align:'center'}}
                    />
                </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                    確定
                </Button>
            </DialogActions>
        </Dialog>
    </Container>
    );
}