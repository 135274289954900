import React from 'react';
import TimelineItem from '../MyComponent/TimelineItem';
import './Brand_story.css';
import { Container, Placeholder } from 'react-bootstrap';
import Footer from '../MyComponent/footer';
import Placeholder1 from '../MyComponent/placeholder1';
import ProgressCircle from '../MyComponent/ProgressCircles';

export default function BrandStoryPage(){
    return(
    <div>
        <Placeholder/>
        <Container>
            <BrandStory/>
        </Container>
        <Placeholder1/>
        <ProgressCircle/>
        <Footer/>
    </div>
    )
}

const timelineData = [
    {
        date: '1991年',
        category: {
            tag: '開始',
            color: '#FFD700'
        },
        text: '開始接觸漁業批發，累積相關知識。'
    },
    {
        date: '1995年',
        category: {
            tag: '發展',
            color: '#02C874'
        },
        text: '開始學習漁獲處理，確保提供的魚貨新鮮且衛生。'
    },
    {
        date: '1997年',
        category: {
            tag: '發展',
            color: '#02C874'
        },
        text: '開始於岡山魚市場攤販販售。'
    },
    {
        date: '2015年3月',
        category: {
            tag: '發展',
            color: '#02C874'
        },
        text: '與專業漁民建立合作關係，確保供應量和魚貨的品質。'
    },
    {
        date: '2023年10月',
        category: {
            tag: '里程碑',
            color: '#6A6AFF'
        },
        text: '開始經營網站，拓展線上通路。'
    },

];

function BrandStory() {
    return (
        <div>
            <div className="additional-content">
                <p>
                達富水產於1991年從事漁業，細心的水產處理技巧及經驗三代相傳，至今長達30年的專業，將於2023年透過網路平台開啟供應鏈！</p>
                <p>
                專業的加工技巧、嚴格品控，從魚塭捕撈到加工完成，我們從不在衛生要求上馬虎，水質穩定及食用安全即是達富最重視的事情。</p>
                <p>
                從岡山魚市場的攤販發跡，如今我們與科技接軌，透過網路商店讓客戶能輕鬆選購高品質且美味的新鮮水產。</p>
                <p>
                誠心歡迎您蒞臨本網站，您可以信任我們長年來對於水產的熱情及專業底蘊！</p>
            </div>
            <div className="timeline-container">
                {timelineData.map((data, idx) => (
                    <TimelineItem className="timeline-event" data={data} key={idx} />
                ))}
            </div>
        </div>
    );
}

